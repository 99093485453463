<template>
  <svg
    class="group"
    width="436"
    height="456"
    viewBox="0 0 400 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :class="props.backgroundClass" filter="url(#filter0_dddddd_5626_22399)">
      <path
        d="M80.1777 57.1777C80.1777 25.6975 105.698 0.177734 137.178 0.177734H298.178C329.658 0.177734 355.178 25.6975 355.178 57.1777V218.178C355.178 249.658 329.658 275.178 298.178 275.178H137.178C105.698 275.178 80.1777 249.658 80.1777 218.178V57.1777Z"
        fill="currentColor"
      />
      <path
        d="M80.6777 57.1777C80.6777 25.9736 105.974 0.677734 137.178 0.677734H298.178C329.382 0.677734 354.678 25.9736 354.678 57.1777V218.178C354.678 249.382 329.382 274.678 298.178 274.678H137.178C105.974 274.678 80.6777 249.382 80.6777 218.178V57.1777Z"
        stroke="white"
        stroke-opacity="0.8"
      />
    </g>
    <g
      :class="props.iconClass"
      clip-path="url(#clip0_5626_22399)"
      filter="url(#filter1_i_5626_22399)"
    >
      <path
        d="M289.894 104.944C289.067 101.991 287.454 99.3177 285.229 97.2081C282.94 95.0336 280.135 93.4782 277.079 92.6886C265.64 89.6735 219.811 89.6735 219.811 89.6735C200.706 89.4561 181.605 90.4122 162.617 92.5363C159.56 93.3842 156.761 94.9744 154.467 97.1655C152.213 99.3339 150.581 102.008 149.728 104.938C147.68 115.973 146.684 127.178 146.756 138.402C146.683 149.615 147.675 160.817 149.728 171.866C150.563 174.783 152.189 177.445 154.449 179.595C156.708 181.745 159.522 183.298 162.617 184.121C174.208 187.13 219.811 187.13 219.811 187.13C238.941 187.347 258.066 186.391 277.079 184.267C280.135 183.477 282.94 181.922 285.229 179.747C287.482 177.597 289.084 174.923 289.888 172.012C291.99 160.981 293.012 149.771 292.94 138.542C293.098 127.265 292.077 116.002 289.894 104.938V104.944ZM205.242 159.257V117.552L243.371 138.408L205.242 159.257Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_5626_22399"
        x="0.177734"
        y="0.177734"
        width="435"
        height="455"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76726" />
        <feGaussianBlur stdDeviation="1.1069" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5626_22399"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.6501" />
        <feGaussianBlur stdDeviation="2.66004" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5626_22399"
          result="effect2_dropShadow_5626_22399"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.5216" />
        <feGaussianBlur stdDeviation="5.00862" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5626_22399"
          result="effect3_dropShadow_5626_22399"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="22.3363" />
        <feGaussianBlur stdDeviation="8.93452" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5626_22399"
          result="effect4_dropShadow_5626_22399"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="41.7776" />
        <feGaussianBlur stdDeviation="16.711" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5626_22399"
          result="effect5_dropShadow_5626_22399"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="100" />
        <feGaussianBlur stdDeviation="40" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5626_22399"
          result="effect6_dropShadow_5626_22399"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5626_22399"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_5626_22399"
        x="146.756"
        y="65.3093"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_5626_22399"
        />
      </filter>
      <clipPath id="clip0_5626_22399">
        <rect
          width="146.184"
          height="146.184"
          fill="white"
          transform="translate(146.756 65.3093)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#1F1F1F] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
