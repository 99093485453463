<template>
  <svg
    class="group"
    width="436"
    height="455"
    viewBox="0 0 400 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :class="props.backgroundClass" filter="url(#filter0_dddddd_5626_22457)">
      <path
        d="M80.1777 57C80.1777 25.5198 105.698 0 137.178 0H298.178C329.658 0 355.178 25.5198 355.178 57V218C355.178 249.48 329.658 275 298.178 275H137.178C105.698 275 80.1777 249.48 80.1777 218V57Z"
        fill="currentColor"
      />
      <path
        d="M80.6777 57C80.6777 25.7959 105.974 0.5 137.178 0.5H298.178C329.382 0.5 354.678 25.7959 354.678 57V218C354.678 249.204 329.382 274.5 298.178 274.5H137.178C105.974 274.5 80.6777 249.204 80.6777 218V57Z"
        stroke="white"
        stroke-opacity="0.8"
      />
    </g>
    <g
      :class="props.iconClass"
      clip-path="url(#clip0_5626_22457)"
      filter="url(#filter1_i_5626_22457)"
    >
      <path
        d="M222.179 76.0345C229.08 75.9277 235.941 75.991 242.795 75.9277C243.21 83.999 246.112 92.2206 252.021 97.927C257.917 103.776 266.257 106.453 274.372 107.359V128.591C266.767 128.342 259.127 126.76 252.226 123.485C249.221 122.125 246.421 120.373 243.68 118.582C243.645 133.989 243.744 149.376 243.582 164.72C243.17 172.091 240.738 179.427 236.451 185.501C229.555 195.613 217.584 202.205 205.289 202.411C197.748 202.842 190.215 200.785 183.788 196.997C173.139 190.717 165.645 179.221 164.553 166.883C164.416 164.27 164.395 161.652 164.49 159.037C165.439 149.004 170.402 139.406 178.106 132.877C186.837 125.273 199.069 121.65 210.521 123.794C210.628 131.604 210.316 139.406 210.316 147.217C205.084 145.524 198.97 145.999 194.399 149.174C191.054 151.378 188.526 154.619 187.205 158.4C186.114 161.074 186.426 164.043 186.489 166.883C187.743 175.535 196.063 182.808 204.945 182.021C210.834 181.958 216.477 178.541 219.546 173.538C220.538 171.786 221.65 169.995 221.709 167.935C222.227 158.503 222.021 149.111 222.084 139.679C222.128 118.424 222.021 97.227 222.183 76.0385L222.179 76.0345Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_5626_22457"
        x="0.177734"
        y="0"
        width="435"
        height="455"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76726" />
        <feGaussianBlur stdDeviation="1.1069" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5626_22457"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.6501" />
        <feGaussianBlur stdDeviation="2.66004" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5626_22457"
          result="effect2_dropShadow_5626_22457"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.5216" />
        <feGaussianBlur stdDeviation="5.00862" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5626_22457"
          result="effect3_dropShadow_5626_22457"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="22.3363" />
        <feGaussianBlur stdDeviation="8.93452" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5626_22457"
          result="effect4_dropShadow_5626_22457"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="41.7776" />
        <feGaussianBlur stdDeviation="16.711" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5626_22457"
          result="effect5_dropShadow_5626_22457"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="100" />
        <feGaussianBlur stdDeviation="40" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5626_22457"
          result="effect6_dropShadow_5626_22457"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5626_22457"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_5626_22457"
        x="156.105"
        y="75.9277"
        width="126.547"
        height="130.546"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_5626_22457"
        />
      </filter>
      <clipPath id="clip0_5626_22457">
        <rect
          width="126.546"
          height="126.546"
          fill="white"
          transform="translate(156.105 75.9277)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#1F1F1F] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
