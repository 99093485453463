<template>
  <div class="whatsapp__wrapper">
    <a
      :href="`https://api.whatsapp.com/send?phone=${props.phone}`"
      :class="
        store.currentSite &&
        store.currentSite.settings &&
        !store.currentSite.settings.intercom.integrate
          ? 'has-bottom'
          : ''
      "
      target="_blank"
      class="whatsapp__launcher"
    >
      <svg viewBox="69.936 83.869 38.76 39" width="48" height="48">
        <defs>
          <pattern
            id="pattern1_whatsapp_desktop_logo"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
            patternTransform="matrix(1, 0, 0, 1, -0.000031, 0.000053)"
          >
            <use transform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#image1" />
          </pattern>
          <linearGradient
            id="paint0_linear_whatsapp_desktop_logo"
            x1="19.404"
            y1="36.983"
            x2="19.404"
            y2="1.583"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(1, 0, 0, 1, 69.935959, 83.869057)"
          >
            <stop stop-color="#20B038" />
            <stop offset="1" stop-color="#60D66A" />
          </linearGradient>
          <pattern
            id="pattern2_whatsapp_desktop_logo"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
            patternTransform="matrix(1, 0, 0, 1, -0.000031, 0.000053)"
          >
            <use transform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#image1" />
          </pattern>
          <linearGradient
            id="paint1_linear_whatsapp_desktop_logo"
            x1="19.404"
            y1="37.618"
            x2="19.404"
            y2=".948"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(1, 0, 0, 1, 69.935959, 83.869057)"
          >
            <stop stop-color="#F9F9F9" />
            <stop offset="1" stop-color="#fff" />
          </linearGradient>
          <pattern
            id="pattern3_whatsapp_desktop_logo"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
            patternTransform="matrix(1, 0, 0, 1, -0.000027, 0.000056)"
          >
            <use transform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#image2" />
          </pattern>
        </defs>
        <path
          fill="url(#pattern1_whatsapp_desktop_logo)"
          d="M 69.936 83.869 L 108.696 83.869 L 108.696 122.869 L 69.936 122.869 Z"
        />
        <path
          d="M 71.203 121.483 L 73.775 112.075 C 72.194 109.312 71.364 106.183 71.369 102.999 C 71.35 92.963 79.451 84.812 89.408 84.812 C 94.239 84.812 98.776 86.697 102.193 90.135 C 105.61 93.573 107.483 98.138 107.483 102.999 C 107.483 113.017 99.383 121.169 89.426 121.169 C 86.407 121.173 83.436 120.41 80.793 118.951 L 71.203 121.483 Z M 81.216 115.679 L 81.767 116.012 C 84.078 117.389 86.718 118.117 89.409 118.119 C 97.675 118.119 104.417 111.335 104.417 103.018 C 104.417 98.988 102.856 95.181 100.027 92.334 C 97.196 89.489 93.412 87.899 89.407 87.899 C 81.122 87.899 74.38 94.683 74.38 103 C 74.38 105.847 75.17 108.638 76.677 111.04 L 77.026 111.614 L 75.501 117.196 L 81.214 115.68 Z"
          fill="#fff"
        />
        <path
          d="M 71.828 120.854 L 74.308 111.761 C 72.779 109.094 71.974 106.073 71.975 102.999 C 71.975 93.333 79.801 85.459 89.408 85.459 C 94.074 85.459 98.446 87.289 101.752 90.597 C 105.033 93.889 106.871 98.351 106.859 102.999 C 106.859 112.666 99.034 120.54 89.426 120.54 C 86.506 120.54 83.64 119.8 81.086 118.396 L 71.828 120.854 Z"
          fill="url(#paint0_linear_whatsapp_desktop_logo)"
        />
        <path
          fill="url(#pattern2_whatsapp_desktop_logo)"
          d="M 69.936 83.869 L 108.696 83.869 L 108.696 122.869 L 69.936 122.869 Z"
        />
        <path
          d="M 71.203 121.483 L 73.775 112.075 C 72.194 109.312 71.364 106.183 71.369 102.999 C 71.35 92.963 79.451 84.812 89.408 84.812 C 94.239 84.812 98.776 86.697 102.193 90.135 C 105.61 93.573 107.483 98.138 107.483 102.999 C 107.483 113.017 99.383 121.169 89.426 121.169 C 86.407 121.173 83.436 120.41 80.793 118.951 L 71.203 121.483 Z M 81.216 115.679 L 81.767 116.012 C 84.078 117.389 86.718 118.117 89.409 118.119 C 97.675 118.119 104.417 111.335 104.417 103.018 C 104.417 98.988 102.856 95.181 100.027 92.334 C 97.196 89.489 93.412 87.899 89.407 87.899 C 81.122 87.899 74.38 94.683 74.38 103 C 74.38 105.847 75.17 108.638 76.677 111.04 L 77.026 111.614 L 75.501 117.196 L 81.214 115.68 Z"
          fill="url(#paint1_linear_whatsapp_desktop_logo)"
        />
        <path
          fill="url(#pattern3_whatsapp_desktop_logo)"
          d="M 78.57 93.111 L 100.246 93.111 L 100.246 113.628 L 78.57 113.628 Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M 84.907 95.384 C 84.577 94.626 84.209 94.608 83.897 94.608 C 83.621 94.589 83.327 94.589 83.015 94.589 C 82.721 94.589 82.225 94.7 81.803 95.162 C 81.38 95.624 80.223 96.715 80.223 98.952 C 80.223 101.188 81.84 103.351 82.06 103.646 C 82.28 103.942 85.183 108.674 89.775 110.504 C 93.596 112.019 94.368 111.724 95.195 111.631 C 96.021 111.539 97.858 110.541 98.244 109.468 C 98.611 108.415 98.611 107.491 98.501 107.306 C 98.391 107.121 98.078 107.01 97.637 106.77 C 97.178 106.548 94.974 105.44 94.551 105.291 C 94.129 105.143 93.835 105.069 93.541 105.513 C 93.247 105.975 92.384 106.992 92.108 107.287 C 91.851 107.583 91.576 107.62 91.135 107.398 C 90.675 107.177 89.225 106.696 87.497 105.143 C 86.157 103.942 85.257 102.445 84.981 102.001 C 84.724 101.539 84.944 101.299 85.183 101.077 C 85.385 100.874 85.643 100.541 85.863 100.282 C 86.083 100.023 86.156 99.82 86.322 99.524 C 86.469 99.229 86.395 98.951 86.285 98.73 C 86.175 98.526 85.311 96.29 84.907 95.384 Z"
          fill="#fff"
        />
      </svg>
    </a>
  </div>
</template>

<script setup>
import { useMainStore } from "~/store";

const store = useMainStore();
const props = defineProps({
  phone: {
    required: true,
    type: String,
  },
});
</script>

<style>
.whatsapp__wrapper {
  @apply w-0 h-0 z-[2147483002] fixed;
}

.whatsapp__launcher {
  @apply bottom-24  z-[2147483004] right-5 w-12 h-12 cursor-pointer block fixed;
}

.has-bottom {
  @apply bottom-5;
}

.whatsapp__launcher:hover {
  background-size: 0 2px !important;
}
</style>
